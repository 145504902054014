/* This CSS styles are needed for customize the cropper and slider libraries */

/* range slider */
.rangeslider-horizontal {
  position: relative;
  height: 4px !important;
  width: 300px !important;
  background-color: #d1d1d5 !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #6b57e1 !important;
  box-shadow: none !important;
  height: 5px;
}
.rangeslider-horizontal .rangeslider__handle:after {
  display: none !important;
}
.rangeslider .rangeslider__handle {
  box-shadow: none !important;
  cursor: pointer;
}
.rangeslider-horizontal .rangeslider__handle {
  position: absolute;
  top: -6px;
  border: 2px solid #ffffff !important;
  background: #6b57e1 !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 100%;
  margin-left: -9px;
}
.rangeslider__handle-tooltip {
  display: none !important;
}
